import React, { useState } from 'react';
import AnaGrup from './AnaGrup/AnaGrup';
import AltGrup from './AltGrup/AltGrup';
import UrunListesi from './UrunListesi/UrunListesi';


const UrunYonetimi: React.FC = () => {
    const [selectedGroup, setSelectedGroup] = useState<string>('');  // Ana grup seçimi
    const [selectedSubGroup, setSelectedSubGroup] = useState<string>('');  // Alt grup seçimi
    const [altGroupData, setAltGroupData] = useState<string[]>([]);  // Alt grup verisi
  
    return (
      <div className="flex h-[65rem]">
        <div className="w-1/3 p-4 flex flex-col">
          <AnaGrup onSelect={setSelectedGroup} onDataUpdate={setAltGroupData} />
          <AltGrup selectedGroup={selectedGroup} onSelect={setSelectedSubGroup}  />
        </div>
        <div className="w-2/3 p-4">
          <UrunListesi selectedSubGroup={selectedSubGroup} />
        </div>
      </div>
    );
  };
  
  export default UrunYonetimi;