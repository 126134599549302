import React, { createContext, useContext, useState, useEffect } from 'react';

// Şube Context'ini oluşturuyoruz
interface SubeContextType {
  selectedSubeID: number | null;
  changeSube: (subeID: number) => void;
  subeler: Array<{ subeID: number; subeAdi: string }> | null;
  setSubeler: (subeler: Array<{ subeID: number; subeAdi: string }>) => void; // Yeni fonksiyon
}

const SubeContext = createContext<SubeContextType | undefined>(undefined);

// Şube sağlayıcısı (provider)
export const SubeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedSubeID, setSelectedSubeID] = useState<number | null>(null);
  const [subeler, setSubeler] = useState<Array<{ subeID: number; subeAdi: string }> | null>(null);

  // Uygulama başladığında localStorage'dan şubeID ve şube bilgilerini okuyalım
  useEffect(() => {
    const storedSubeID = localStorage.getItem('selectedSubeID');
    const storedSubeler = localStorage.getItem('subeler');

    if (storedSubeID) {
      setSelectedSubeID(Number(storedSubeID));
    }
    if (storedSubeler) {
      setSubeler(JSON.parse(storedSubeler)); // Şube listesini localStorage'dan çek ve state'e ata
    }
  }, []);

  // Şube değiştirme fonksiyonu
  const changeSube = (subeID: number) => {
    setSelectedSubeID(subeID);
    localStorage.setItem('selectedSubeID', subeID.toString());
  };

  return (
    <SubeContext.Provider value={{ selectedSubeID, changeSube, subeler, setSubeler }}>
      {children}
    </SubeContext.Provider>
  );
};

// Context kullanımı için özel hook
export const useSube = () => {
  const context = useContext(SubeContext);
  if (!context) {
    throw new Error('useSube must be used within a SubeProvider');
  }
  return context;
};
