import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  DashboardIcon,
  FileTextIcon,
  ArchiveIcon,
  GearIcon,
  BoxIcon,
  BarChartIcon,
} from "@radix-ui/react-icons";

const Sidebar: React.FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openReportsMenu, setOpenReportsMenu] = useState(false);

  return (
    <aside className="w-64 bg-white text-gray-800 flex flex-col border-r border-gray-200">
      <div className="p-4 font-bold text-lg">Admin Panel</div>
      <nav className="flex-1">
        <ul>
          <Link to="/admin/dashboard">
            <li className="p-4 hover:bg-gray-200 flex items-center">
              <DashboardIcon className="mr-2" />
              Gösterge Paneli
            </li>
          </Link>
          <Link to="/admin/urunyonetimi">
            <li className="p-4 hover:bg-gray-200 flex items-center">
              <FileTextIcon className="mr-2" />
              Ürün Yönetimi
            </li>
          </Link>
          <Link to="/admin/customers">
            <li className="p-4 hover:bg-gray-200 flex items-center">
              <ArchiveIcon className="mr-2" />
              Müşteriler
            </li>
          </Link>
          <Link to="/admin/settings">
            <li className="p-4 hover:bg-gray-200 flex items-center">
              <GearIcon className="mr-2" />
              Ayarlar
            </li>
          </Link>

          <li
            className="p-4 hover:bg-gray-200 flex items-center cursor-pointer"
            onClick={() => setOpenMenu(!openMenu)}
          >
            <BoxIcon className="mr-2" />
            Ürün Yönetimi
            <svg
              className={`ml-auto transition-transform transform ${
                openMenu ? "rotate-90" : ""
              }`}
              width="12"
              height="12"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M9 6l6 6-6 6"></path>
            </svg>
          </li>
          {openMenu && (
            <ul className="ml-4 mt-2">
              <Link to="/admin/products">
                <li className="p-2 hover:bg-gray-200 flex items-center">
                  <BoxIcon className="mr-2" />
                  Ürün Listesi
                </li>
              </Link>
              <Link to="/admin/categories">
                <li className="p-2 hover:bg-gray-200 flex items-center">
                  <BarChartIcon className="mr-2" />
                  Kategori Yönetimi
                </li>
              </Link>
              <Link to="/admin/stock">
                <li className="p-2 hover:bg-gray-200 flex items-center">
                  <BarChartIcon className="mr-2" />
                  Stok Takibi
                </li>
              </Link>
            </ul>
          )}

          <li
            className="p-4 hover:bg-gray-200 flex items-center cursor-pointer"
            onClick={() => setOpenReportsMenu(!openReportsMenu)}
          >
            <BarChartIcon className="mr-2" />
            Raporlar
            <svg
              className={`ml-auto transition-transform transform ${
                openReportsMenu ? "rotate-90" : ""
              }`}
              width="12"
              height="12"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M9 6l6 6-6 6"></path>
            </svg>
          </li>
          {openReportsMenu && (
            <ul className="ml-4 mt-2">
              <Link to="/admin/sales-reports">
                <li className="p-2 hover:bg-gray-200 flex items-center">
                  <BarChartIcon className="mr-2" />
                  Satış Raporları
                </li>
              </Link>
              <Link to="/admin/stock-reports">
                <li className="p-2 hover:bg-gray-200 flex items-center">
                  <BarChartIcon className="mr-2" />
                  Stok Raporları
                </li>
              </Link>
            </ul>
          )}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
