import React, { useState } from "react";

import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";

const AdminPage: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Sidebar açık/kapalı durumu

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen); // Sidebar'ı aç/kapa
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar'ı sadece isSidebarOpen true olduğunda göster */}
      {isSidebarOpen && <Sidebar />}
      <div className="flex-1 bg-gray-50">
        <Header toggleSidebar={toggleSidebar} /> {/* Header'a toggleSidebar fonksiyonu prop olarak geçiliyor */}
        <div className="p-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
