import React, { useState, useEffect, useRef } from "react";
import { Upload } from "lucide-react";

interface PhotoUploadProps {
  onFileSelect: (file: File) => void;
  file?: File | null;
}

const PhotoUpload: React.FC<PhotoUploadProps> = ({ onFileSelect, file }) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [preview, setPreview] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      validateAndSetFile(file);
      onFileSelect(file); // Seçilen dosyayı üst komponentlere ilet
    }
  };
  
  const validateAndSetFile = (file: File) => {
    const validTypes = ["image/png", "image/jpeg", "image/gif"];
    if (validTypes.includes(file.type)) {
      setSelectedFile(file);
      setError(null);
    } else {
      setError("Lütfen PNG, JPG veya GIF dosyası yükleyin.");
      setSelectedFile(undefined);
      setPreview(null);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true); // Sürükleme başladığında isDragging durumunu true yap
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const { clientX, clientY } = event;
    
    // Fare div'in sınırları dışına çıktığında isDragging'i false yap
    if (
      clientX < rect.left ||
      clientX >= rect.right ||
      clientY < rect.top ||
      clientY >= rect.bottom
    ) {
      setIsDragging(false);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true); // Sürükleme devam ettiği sürece isDragging true olmalı
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      validateAndSetFile(file);
      onFileSelect(file); // Dosya seçimi başarılı olduğunda dosyayı üst komponentlere ilet
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Tıklama ile dosya seçimini aç
    }
  };

  return (
    <div 
      className="p-4 border border-gray-300 rounded-lg hover:border-black hover:bg-gray-100 transition-colors duration-300 ease-in-out"
      onMouseEnter={() => setIsDragging(true)}
      onMouseLeave={() => setIsDragging(false)}
      onClick={handleClick}
    >
      <div 
        className={`relative flex items-center justify-center w-full h-40 border-2 border-dashed rounded-lg cursor-pointer ${isDragging ? "border-black bg-gray-100" : "border-gray-300 bg-gray-50"} hover:bg-gray-100 transition-colors duration-300 ease-in-out`} 
        onDragEnter={handleDragEnter} 
        onDragOver={handleDragOver} 
        onDragLeave={handleDragLeave} 
        onDrop={handleDrop}
      >
        {preview ? (
          <img 
            src={preview} 
            alt="Preview" 
            className="absolute inset-0 w-full h-full object-cover rounded-lg transition-transform duration-300 ease-in-out transform hover:scale-95 hover:opacity-80"
          />
        ) : (
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <Upload className={`w-8 h-8 mb-3 ${isDragging ? "text-black" : "text-gray-400"} hover:text-black`} />
            <p className={`mb-2 text-sm ${isDragging ? "text-black" : "text-gray-500"}`}>
              <span className="font-semibold">Dosya seçmek için tıklayın</span> veya sürükleyip bırakın
            </p>
            <p className={`text-xs ${isDragging ? "text-black" : "text-gray-500"}`}>
              {file ? file.name : "PNG, JPG veya GIF (MAX. 800x400px)"}
            </p>
            {error && <p className="text-xs text-red-500 mt-2">{error}</p>}
          </div>
        )}
        <input 
          id="photo-upload" 
          type="file" 
          className="hidden" 
          onChange={handleFileChange} 
          ref={fileInputRef}
          accept="image/png,image/jpeg,image/gif"
        />
      </div>
    </div>
  );
};

export default PhotoUpload;
