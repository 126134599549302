import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { Pencil1Icon, PlusIcon, CheckIcon } from "@radix-ui/react-icons";
import { getData, postData, putData } from "../../../services/api";
import { toast } from "react-toastify";
import { useSube } from "../../../Context/SubeContext";
import PhotoUpload from "../../../components/PhotoUpload/PhotoUpload"; // Fotoğraf yükleme komponenti

interface AltGrupProps {
  selectedGroup: string; // Ana grup ID'si
  onSelect: (selectedSubGroup: string) => void;
}

interface AltKategori {
  altKategoriID: number;
  anaKategoriID: number;
  kategoriAdi: string;
  fotoURL: string;
  olusturulmaTarihi: string;
  subeID: number;
}

interface AnaKategori {
  anaKategoriID: number;
  kategoriAdi: string;
}

const AltGrup: React.FC<AltGrupProps> = ({ selectedGroup, onSelect }) => {
  const { selectedSubeID } = useSube(); // SubeID'yi alıyoruz
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [altGrupData, setAltGrupData] = useState<AltKategori[]>([]); // Alt grup verileri
  const [filteredAltGrupData, setFilteredAltGrupData] = useState<AltKategori[]>([]); // Filtrelenmiş alt grup verileri
  const [selectedItem, setSelectedItem] = useState<AltKategori | null>(null); // Seçilen öğe (güncelleme için)
  const [newSubGroup, setNewSubGroup] = useState<string>(""); // Modal'dan gelen yeni alt grup adı
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Seçilen fotoğraf dosyası
  const [anaGrupData, setAnaGrupData] = useState<AnaKategori[]>([]); // Ana grup verileri
  const [selectedAnaGrup, setSelectedAnaGrup] = useState<number | null>(null); // Seçilen ana grup ID
  const [selectedId, setSelectedId] = useState<number | null>(null); // Tablo üzerindeki seçili ID

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setNewSubGroup("");
    setSelectedFile(null);
    setSelectedItem(null); // Seçili öğeyi temizle
    setSelectedId(null); // Seçimi temizle
  };

  const fetchAltGrupData = async () => {
    if (!selectedSubeID) {
      toast.error("Lütfen bir şube seçiniz.", { position: "top-right" });
      return;
    }

    try {
      const data: AltKategori[] = await getData(`/AltKategori/listele/${selectedSubeID}`);
      setAltGrupData(data);
      toast.success("Alt grup verileri başarıyla yüklendi!", { position: "top-right" });
    } catch (error) {
      toast.error("Alt grup verileri yüklenirken bir hata oluştu.", { position: "top-right" });
    }
  };

  const fetchAnaGrupData = async () => {
    if (!selectedSubeID) {
      toast.error("Lütfen bir şube seçiniz.", { position: "top-right" });
      return;
    }

    try {
      const data: AnaKategori[] = await getData(`/AnaKategori/list/${selectedSubeID}`);
      setAnaGrupData(data);
      toast.success("Ana grup verileri başarıyla yüklendi!", { position: "top-right" });
    } catch (error) {
      toast.error("Ana grup verileri yüklenirken bir hata oluştu.", { position: "top-right" });
    }
  };

  useEffect(() => {
    fetchAltGrupData(); // Şube ID değiştikçe alt grup verilerini yükle
    fetchAnaGrupData(); // Şube ID değiştikçe ana grup verilerini yükle
  }, [selectedSubeID]);

  useEffect(() => {
    // selectedGroup değiştiğinde alt grupları filtrele
    if (selectedGroup && Array.isArray(altGrupData)) {
      const filteredData = altGrupData.filter(
        (altKategori) => String(altKategori.anaKategoriID) === selectedGroup
      );
      setFilteredAltGrupData(filteredData);
  
      if (filteredData.length > 0) {
        onSelect(String(filteredData[0].altKategoriID));
      } else {
        onSelect(""); // Eğer eşleşen bir alt grup yoksa boş seç
      }
    } else {
      setFilteredAltGrupData([]); // Eğer altGrupData bir dizi değilse veya boşsa
      onSelect(""); // Hiçbir şey seçilmeyecek
    }
  }, [selectedGroup, altGrupData, onSelect]);
  

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
  };

  // Seçilen öğeyi güncelleme için hazırlama
  const handleEdit = (item: AltKategori) => {
    setSelectedItem(item); // Seçilen öğeyi state'e ata
    setNewSubGroup(item.kategoriAdi); // Seçilen öğe adıyla formu doldur
    setSelectedAnaGrup(item.anaKategoriID); // Ana kategori ID'sini doldur
    openModal(); // Modal'ı aç
  };

  // Güncelleme isteği
  const handleUpdate = async () => {
    if (!newSubGroup || !selectedSubeID || !selectedAnaGrup || !selectedItem) {
      toast.error("Lütfen tüm bilgileri doldurunuz.", { position: "top-right" });
      return;
    }

    const formData = new FormData();
    formData.append("AltKategoriID", String(selectedItem.altKategoriID)); // Güncellenen kategori ID'si
    formData.append("AnaKategoriID", String(selectedAnaGrup)); // Seçilen Ana grup ID
    formData.append("KategoriAdi", newSubGroup); // Güncellenen alt kategori adı
    formData.append("SubeID", String(selectedSubeID)); // Şube ID
    formData.append("OlusturulmaTarihi", new Date().toISOString()); // Oluşturulma tarihi
    formData.append("FotoURL", "selectedFile"); // Seçilen fotoğraf dosyası

    if (selectedFile) {
      formData.append("yeniFoto", selectedFile); // Eğer yeni fotoğraf seçildiyse
    }

    try {
      await putData("/AltKategori/guncelle", formData, true);
      toast.success("Alt Kategori başarıyla güncellendi!", { position: "top-right" });
      fetchAltGrupData(); // Güncellemeden sonra listeyi güncelle
      closeModal();
    } catch (error) {
      toast.error("Alt Kategori güncellenirken bir hata oluştu.", { position: "top-right" });
    }
  };

  // Yeni alt kategori ekleme isteği
  const handleSave = async () => {
    if (!newSubGroup || !selectedFile || !selectedSubeID || !selectedAnaGrup) {
      toast.error("Lütfen tüm bilgileri doldurunuz.", {
        position: "top-right",
      });
      return;
    }

    const formData = new FormData();
    formData.append("AltKategoriID", "0"); // Yeni kategori olduğu için 0
    formData.append("AnaKategoriID", String(selectedAnaGrup)); // Seçilen Ana grup ID
    formData.append("KategoriAdi", newSubGroup); // Yeni alt kategori adı
    formData.append("SubeID", String(selectedSubeID)); // Şube ID
    formData.append("OlusturulmaTarihi", new Date().toISOString()); // Oluşturulma tarihi
    formData.append("foto", selectedFile); // Seçilen fotoğraf dosyası
    formData.append("FotoURL", "selectedFile"); // Seçilen fotoğraf dosyası

    try {
      await postData("/AltKategori/ekle", formData, true);
      toast.success("Alt Kategori başarıyla eklendi!", {
        position: "top-right",
      });
      fetchAltGrupData(); // Kategori eklendikten sonra listeyi güncelle
      closeModal();
    } catch (error) {
      toast.error("Alt Kategori eklenirken bir hata oluştu.", {
        position: "top-right",
      });
    }
  };

  const toggleSelection = (group: AltKategori) => {
    if (selectedId === group.altKategoriID) {
      // Seçiliyse seçimi kaldır
      setSelectedItem(null);
      setSelectedId(null);
      onSelect("");
    } else {
      // Seçilmemişse seç
      setSelectedItem(group);
      setSelectedId(group.altKategoriID);
      onSelect(String(group.altKategoriID));
    }
  };

  return (
    <div className="bg-white p-4 rounded shadow h-1/2 overflow-y-auto">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-bold mb-2">Alt grup</h2>
        <div className="flex space-x-2">
          <button onClick={openModal} className="p-2 rounded-md flex items-center bg-green-100 text-green-600">
            <PlusIcon className="mr-1" /> Ekle
          </button>
          <button
            onClick={() => {
              if (selectedItem) {
                handleEdit(selectedItem);
              } else {
                toast.error("Lütfen düzenlemek için bir alt grup seçin.", { position: "top-right" });
              }
            }}
            className={`p-2 rounded-md flex items-center ${
              selectedItem ? "bg-yellow-100 text-yellow-600" : "bg-gray-100 text-gray-600"
            }`}
          >
            <Pencil1Icon className="mr-1" /> Güncelle
          </button>
        </div>
      </div>

      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="border px-4 py-2">
              <input type="checkbox" disabled />
            </th>
            <th className="border px-4 py-2">Kategori Adı</th>
            <th className="border px-4 py-2">Fotoğraf</th>
          </tr>
        </thead>
        <tbody>
          {filteredAltGrupData.map((subGroup) => (
            <tr
              key={subGroup.altKategoriID}
              className="cursor-pointer hover:bg-gray-200"
              onClick={() => toggleSelection(subGroup)}
            >
              <td className="border px-4 py-2">
                <input
                  type="checkbox"
                  checked={selectedId === subGroup.altKategoriID}
                  onChange={() => toggleSelection(subGroup)}
                />
              </td>
              <td className="border px-4 py-2">{subGroup.kategoriAdi}</td>
              <td className="border px-4 py-2">
                {subGroup.fotoURL ? (
                  <div
                    className="h-16 w-16 bg-cover bg-center rounded"
                    style={{
                      backgroundImage: `url(${
                        subGroup.fotoURL.startsWith("http")
                          ? subGroup.fotoURL
                          : `https://${subGroup.fotoURL}`
                      })`,
                    }}
                  ></div>
                ) : (
                  "Fotoğraf Yok"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal - Alt Grup Ekleme/Güncelleme */}
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title={selectedItem ? "Alt Grup Güncelle" : "Yeni Alt Grup Ekle"}
        onSave={selectedItem ? handleUpdate : handleSave} // Güncelleme modundaysa güncelle
        width="max-w-lg"
      >
        <div className="space-y-4">
          {/* Ana Grup Seçimi */}
          <div>
            <label className="block text-sm font-medium">Ana Grup Seç</label>
            <select
              value={selectedAnaGrup || ""}
              onChange={(e) => setSelectedAnaGrup(Number(e.target.value))}
              className="mt-1 block w-full border border-gray-300 rounded p-2"
            >
              <option value="">Ana Grup Seçiniz</option>
              {anaGrupData.map((group) => (
                <option key={group.anaKategoriID} value={group.anaKategoriID}>
                  {group.kategoriAdi}
                </option>
              ))}
            </select>
          </div>

          {/* Alt Kategori Adı */}
          <div>
            <label className="block text-sm font-medium">Alt Grup Adı</label>
            <input
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded p-2"
              value={newSubGroup}
              onChange={(e) => setNewSubGroup(e.target.value)}
              placeholder="Alt grup adını giriniz"
            />
          </div>

          {/* Fotoğraf Yükleme */}
          <PhotoUpload onFileSelect={handleFileSelect} file={selectedFile} />

          <div onClick={selectedItem ? handleUpdate : handleSave} className="text-center bg-blue-500 p-2 rounded text-white cursor-pointer">
            {selectedItem ? "Güncelle" : "Ekle"}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AltGrup;
