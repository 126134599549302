import React from 'react';
import AppRoutes from './routes/AppRoutes';
import { SubeProvider } from './Context/SubeContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  return (
    <SubeProvider>
      <AppRoutes />
      {/* ToastContainer'ı buraya ekliyoruz */}
      <ToastContainer 
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" // Tailwind ile uyumlu stil
      />
    </SubeProvider>
  );
};

export default App;
