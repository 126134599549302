import axios, { AxiosError } from 'axios';

// Axios instance oluşturma
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    
  },
});

// Token ekleyen bir interceptor
api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      // Eğer istek login ise token ekleme
      if (token && config.url !== '/Auth/login') {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

// Hata loglama fonksiyonu
const handleError = (error: AxiosError) => {
  if (error.response) {
    // Sunucu yanıt verdi fakat hata durumu döndü
    console.error('Response error:', error.response.data);
  } else if (error.request) {
    // İstek yapıldı fakat cevap alınamadı
    console.error('Request error:', error.request);
  } else {
    // Başka bir hata meydana geldi
    console.error('Error:', error.message);
  }
};

// GET isteği
export const getData = async (url: string) => {
  try {
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    handleError(error as AxiosError);
    throw error;
  }
};

// POST isteği
export const postData = async (url: string, data: any, isMultipart: boolean = false) => {
  try {
    let headers = {};
    
    if (isMultipart) {
      headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    const response = await api.post(url, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleError(error as AxiosError);
    throw error;
  }
};
// PUT isteği
export const putData = async (url: string, data: any, isMultipart: boolean = false) => {
  try {
    let headers = {};

    if (isMultipart) {
      headers = {
        'Content-Type': 'multipart/form-data',
      };
    }

    const response = await api.put(url, data, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleError(error as AxiosError);
    throw error;
  }
};

// DELETE isteği
export const deleteData = async (url: string) => {
  try {
    const response = await api.delete(url);
    return response.data;
  } catch (error) {
    handleError(error as AxiosError);
    throw error;
  }
};
