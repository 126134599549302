import { postData } from './api';

const login = async (kullaniciKodu: string, sifre: string): Promise<{ success: boolean; subeler?: Array<{ subeID: number; subeAdi: string }> }> => {
  try {
    const response = await postData('/Auth/login', { kullaniciKodu, sifre });

    // API yanıtından verileri alıyoruz
    const { token, kullaniciBilgileri, subeler } = response;

    if (!token) {
      throw new Error('Token bulunamadı');
    }

    // Token ve bilgileri localStorage'a kaydediyoruz
    localStorage.setItem('token', token);
    localStorage.setItem('kullaniciBilgileri', JSON.stringify(kullaniciBilgileri));
    localStorage.setItem('subeler', JSON.stringify(subeler));

    return { success: true, subeler };
  } catch (error) {
    console.error('Login error:', error);
    return { success: false };
  }
};


const isAuthenticated = (): boolean => {
  return !!localStorage.getItem('token');
};

const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('kullaniciBilgileri');
  localStorage.removeItem('subeler');
};

const authService = {
  login,
  isAuthenticated,
  logout,
};

export default authService;
