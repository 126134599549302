import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import { PlusIcon, Pencil1Icon, CheckIcon } from "@radix-ui/react-icons";
import { toast } from "react-toastify";
import { useSube } from "../../../Context/SubeContext";
import { getData, postData, putData } from "../../../services/api";
import PhotoUpload from "../../../components/PhotoUpload/PhotoUpload";
import LazyLoad from 'react-lazyload'; // Eklenen kütüphane

interface AnaGrupProps {
  onSelect: (selectedGroup: string) => void;
  onDataUpdate: (data: string[]) => void;
}

interface AnaKategori {
  anaKategoriID: number;
  kategoriAdi: string;
  fotoURL: string;
  olusturulmaTarihi: string;
  subeID: number;
}

const formatDateForSQL = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const AnaGrup: React.FC<AnaGrupProps> = ({ onSelect, onDataUpdate }) => {
  const { selectedSubeID } = useSube();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anaGrupData, setAnaGrupData] = useState<AnaKategori[]>([]);
  const [newGroup, setNewGroup] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<AnaKategori | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setIsEditing(false);
    setSelectedFile(null);
    setNewGroup("");
    setSelectedGroup(null);
    setSelectedId(null);
  };

  const fetchAnaGrupData = async () => {
    if (selectedSubeID === null || selectedSubeID === undefined) {
      toast.error("Lütfen şirket seçiniz.", { position: "top-right" });
      return;
    }

    try {
      const data = await getData(`/AnaKategori/list/${selectedSubeID}`);
      setAnaGrupData(data);
      toast.success("Ana grup verileri başarıyla yüklendi!", {
        position: "top-right",
      });
    } catch (error) {
      toast.error("Veriler yüklenirken bir hata oluştu.", {
        position: "top-right",
      });
    }
  };

  useEffect(() => {
    fetchAnaGrupData();
  }, [selectedSubeID]);

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
  };

  const handleSave = async () => {
    if (!newGroup || (!selectedFile && !isEditing) || !selectedSubeID) {
      toast.error("Lütfen tüm bilgileri doldurunuz.", {
        position: "top-right",
      });
      return;
    }

    const formData = new FormData();
    formData.append("KategoriAdi", newGroup);
    formData.append("SubeID", String(selectedSubeID));
    formData.append("FotoURL", "bos");
    formData.append("AnaKategoriID", "0");
    formData.append("OlusturulmaTarihi", formatDateForSQL(new Date()));

    if (!isEditing && selectedFile) {
      formData.append("foto", selectedFile); // Sadece yeni kayıt sırasında fotoğraf yükle
    }

    try {
      await postData("/AnaKategori/ekle", formData, true);
      toast.success("Ana Kategori başarıyla eklendi!", {
        position: "top-right",
      });
      fetchAnaGrupData();
      closeModal();
    } catch (error) {
      toast.error("Kategori eklenirken bir hata oluştu.", {
        position: "top-right",
      });
    }
  };

  const handleEdit = (group: AnaKategori) => {
    setSelectedGroup(group);
    setNewGroup(group.kategoriAdi);
    setSelectedId(group.anaKategoriID);
    setIsEditing(true);
    openModal();
  };

  const handleUpdate = async () => {
    if (!selectedGroup || !newGroup || !selectedSubeID) {
      toast.error("Lütfen tüm bilgileri doldurunuz.", {
        position: "top-right",
      });
      return;
    }

    const formData = new FormData();
    formData.append("AnaKategoriID", String(selectedGroup.anaKategoriID));
    formData.append("KategoriAdi", newGroup);
    formData.append("SubeID", String(selectedSubeID));
    formData.append("OlusturulmaTarihi", formatDateForSQL(new Date()));

    if (selectedFile) {
      formData.append("yeniFoto", selectedFile);
    }

    formData.append("FotoURL", selectedGroup.fotoURL || "bos");

    try {
      await putData(`/AnaKategori/guncelle`, formData, true);
      toast.success("Ana Kategori başarıyla güncellendi!", {
        position: "top-right",
      });
      fetchAnaGrupData();
      closeModal();
    } catch (error) {
      toast.error("Kategori güncellenirken bir hata oluştu.", {
        position: "top-right",
      });
    }
  };

  const toggleSelection = (group: AnaKategori) => {
    if (selectedId === group.anaKategoriID) {
      // Seçiliyse seçimi kaldır
      setSelectedGroup(null);
      setSelectedId(null);
      onSelect("");
    } else {
      // Seçilmemişse seç
      setSelectedGroup(group);
      setSelectedId(group.anaKategoriID);
      onSelect(String(group.anaKategoriID)); // Seçili kategori ID'sini gönder
    }
  };

  return (
    <div className="bg-white p-4 rounded shadow mb-4 h-1/2 overflow-y-auto">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-bold mb-2">Ana grup</h2>
        <div className="flex space-x-2">
          <button
            onClick={openModal}
            className="p-2 rounded-md flex items-center bg-green-100 text-green-600"
          >
            <PlusIcon className="mr-1" /> Ekle
          </button>
          <button
            onClick={() => {
              if (selectedGroup) {
                handleEdit(selectedGroup);
              } else {
                toast.error("Lütfen düzenlemek için bir grup seçin.", {
                  position: "top-right",
                });
              }
            }}
            className={`p-2 rounded-md flex items-center ${
              selectedGroup
                ? "bg-yellow-100 text-yellow-600"
                : "bg-gray-100 text-gray-600"
            }`}
          >
            <Pencil1Icon className="mr-1" /> Güncelle
          </button>
        </div>
      </div>
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="border px-4 py-2">
              <input type="checkbox" disabled />
            </th>
            <th className="border px-4 py-2" >Fotoğraf</th>

            <th className="border px-4 py-2">Kategori Adı</th>
          </tr>
        </thead>
        <tbody>
          {anaGrupData.map((group) => (
            <tr
              key={group.anaKategoriID}
              className="cursor-pointer hover:bg-gray-200"
              onClick={() => toggleSelection(group)}
            >
              <td className="border px-4 py-2">
                <input
                  type="checkbox"
                  checked={selectedId === group.anaKategoriID}
                  onChange={() => toggleSelection(group)}
                />
              </td>
              <td className="border px-4 py-2">
                {group.fotoURL ? (
                  <div
                    className="h-16 w-16 bg-cover bg-center rounded"
                    style={{
                      backgroundImage: `url(https://${group.fotoURL})`,
                    }}
                  />
                ) : (
                  "Fotoğraf Yok"
                )}
              </td>

              <td className="border px-4 py-2">{group.kategoriAdi}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title={isEditing ? "Ana Grup Düzenle" : "Yeni Ana Grup Ekle"}
        onSave={isEditing ? handleUpdate : handleSave}
        width="max-w-lg"
      >
        <div className="space-y-4">
          {/* Grup Adı Alanı */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Ana Grup Adı
            </label>
            <input
              type="text"
              className="mt-1 block w-full p-2 border border-gray-300 rounded"
              value={newGroup}
              onChange={(e) => setNewGroup(e.target.value)}
              placeholder="Kategori adını giriniz"
            />
          </div>

          {/* Fotoğraf Yükleme Alanı */}
          {!selectedGroup && (
            <PhotoUpload onFileSelect={handleFileSelect} file={selectedFile} />
          )}

          {/* Yüklü Fotoğraf Gösterimi */}
          {selectedGroup && selectedGroup.fotoURL && !selectedFile && (
            <div className="mt-4">
              <p className="text-sm text-gray-600">Yüklü Fotoğraf:</p>
              <img
                src={`https://${selectedGroup.fotoURL}`}
                alt={selectedGroup.kategoriAdi}
                className="h-24 w-24 object-cover rounded border"
              />
            </div>
          )}
        </div>

        {/* Butonlar */}
        <div className="flex justify-end mt-6 space-x-2">
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md"
            onClick={closeModal}
          >
            İptal
          </button>
          {isEditing ? (
            <button
              className="px-4 py-2 bg-yellow-100 text-yellow-600 rounded-md flex items-center"
              onClick={handleUpdate}
            >
              <Pencil1Icon className="mr-1" />
              Güncelle
            </button>
          ) : (
            <button
              className="px-4 py-2 bg-green-100 text-green-600 rounded-md flex items-center"
              onClick={handleSave}
            >
              <PlusIcon className="mr-1" />
              Ekle
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AnaGrup;
