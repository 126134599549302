import React, { useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import { PlusIcon } from '@radix-ui/react-icons';

interface UrunListesiProps {
  selectedSubGroup: string;
}

const UrunListesi: React.FC<UrunListesiProps> = ({ selectedSubGroup }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [urunListesi, setUrunListesi] = useState<string[]>([]);  // Ürün listesi verileri
  const [newUrun, setNewUrun] = useState<string>('');  // Modal'dan gelen yeni ürün

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSave = () => {
    if (newUrun) {
      const updatedData = [...urunListesi, newUrun];
      setUrunListesi(updatedData);
      closeModal();
    }
  };

  return (
    <div className="bg-white p-4 rounded shadow h-full">
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-lg font-bold mb-2">Ürün listesi</h2>
        <button  onClick={openModal} className="p-2 rounded-md text-green-900 bg-green-300">
        <PlusIcon />
      </button>
      </div>
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="border px-4 py-2">Ürün</th>
            <th className="border px-4 py-2">Detay</th>
          </tr>
        </thead>
        <tbody>
          {urunListesi.map((urun, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{urun}</td>
              <td className="border px-4 py-2">Detay</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title="Yeni Ürün Ekle"
        onSave={handleSave}
        width="max-w-lg"  // Modal boyutunu burada ayarlıyoruz
      >
        <div>
          <label className="block text-sm font-medium">Ürün Adı</label>
          <input
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded p-2"
            value={newUrun}
            onChange={(e) => setNewUrun(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default UrunListesi;
