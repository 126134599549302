import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
import authService from "../../services/authService";
import { useSube } from "../../Context/SubeContext";

const LoginPage = () => {
  const [kullaniciKodu, setKullaniciKodu] = useState("");
  const [sifre, setSifre] = useState("");
  const navigate = useNavigate();
  const { setSubeler } = useSube(); // SubeContext'teki setSubeler fonksiyonunu alıyoruz

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // authService.login çağrısı
    const { success, subeler } = await authService.login(kullaniciKodu, sifre);
    
    if (success && subeler) {
      // Şubeleri SubeContext'e kaydet
      setSubeler(subeler);
      // Başarılı giriş sonrası yönlendirme
      navigate("/admin");
    } else {
      alert("Login failed!");
    }
  };

  // Token kontrolü ve otomatik yönlendirme
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/admin");
    }
  }, [navigate]);

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center"
      style={{
        backgroundImage: "url('https://image.muzayedeexpress.com/bg/bg.jpg')",
      }}
    >
      {/* Arka planı blur yapıyoruz */}
      <div className="absolute inset-0 bg-black/30 backdrop-blur-sm"></div>

      <div className="relative z-10 bg-white bg-opacity-85 shadow-md rounded-lg p-8 max-w-md w-full">
        <div className="text-center mb-6">
          <div className="w-16 h-16 bg-gray-200 mx-auto rounded-full mb-4">
            {/* Logo için yer tutucu */}
          </div>
          <h2 className="text-2xl font-bold text-gray-800">Yönetici Girişi</h2>
          <p className="text-gray-500">
            Yönetim paneline erişmek için giriş yapın
          </p>
        </div>
        <form onSubmit={handleLogin}>
          <div className="mb-4 relative">
            <label
              className="block text-gray-700 mb-2 "
              htmlFor="kullaniciKodu"
            >
              Kullanıcı Adı
            </label>
            <div className="relative">
              <input
                id="kullaniciKodu"
                type="text"
                placeholder="Kullanıcı adınızı girin"
                value={kullaniciKodu}
                onChange={(e) => setKullaniciKodu(e.target.value)}
                className="w-full p-3 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              />
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <FontAwesomeIcon icon={faUser} className="text-gray-400" />
              </span>
            </div>
          </div>
          <div className="mb-4 relative">
            <label className="block text-gray-700 mb-2" htmlFor="sifre">
              Şifre
            </label>
            <div className="relative">
              <input
                id="sifre"
                type="password"
                placeholder="Şifrenizi girin"
                value={sifre}
                onChange={(e) => setSifre(e.target.value)}
                className="w-full p-3 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-200"
              />
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <FontAwesomeIcon icon={faLock} className="text-gray-400" />
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mb-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-blue-600 "
              />
              <span className="ml-2 text-gray-700">Beni hatırla</span>
            </label>
            <a href="#" className="text-sm text-blue-600 hover:underline">
              Şifremi unuttum
            </a>
          </div>
          <button
            type="submit"
            className="w-full bg-gray-800 text-white p-3 rounded-md font-bold text-center hover:bg-gray-700"
          >
            Giriş Yap
          </button>
        </form>
        <div className="mt-6 text-center">
          <a href="#" className="text-sm text-gray-600">
            Hesabınız yok mu?{" "}
            <span className="text-blue-600 hover:underline">Kayıt ol</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
