import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/LoginPage/LoginPage';
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';
import AdminPage from '../pages/AdminPage/AdminPage';
import Dashboard from '../pages/DashBoard/Dashboard';
import UrunYonetimi from '../pages/UrunYonetimi/UrunYonetimi';


const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/admin"
        element={
          <ProtectedRoute>
            <AdminPage />
          </ProtectedRoute>
        }
      >
        {/* Nested Routes */}
        <Route path="dashboard" element={<Dashboard />} />
       <Route path="urunyonetimi" element={<UrunYonetimi />} />
         {/* <Route path="customers" element={<Customers />} />
        <Route path="products" element={<Products />} />
        <Route path="categories" element={<Categories />} />
        <Route path="reports" element={<Reports />} />
        <Route path="settings" element={<AnaKategoriTable />} /> */}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
