import React from 'react';
import { useSube } from '../../Context/SubeContext';

const Dashboard: React.FC = () => {
    const { selectedSubeID } = useSube();

  const data = [
    { id: 1, name: 'Ürün A', category: 'Elektronik', stock: 120, price: '₺500', status: 'Aktif' },
    { id: 2, name: 'Ürün B', category: 'Giyim', stock: 80, price: '₺150', status: 'Aktif' },
    { id: 3, name: 'Ürün C', category: 'Gıda', stock: 200, price: '₺75', status: 'Pasif' },
    { id: 4, name: 'Ürün D', category: 'Kitap', stock: 150, price: '₺30', status: 'Aktif' },
  ];

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Gösterge Paneli</h2>
      <p>Şu an seçilen şube ID: {selectedSubeID}</p>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100 text-gray-800">
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Ürün Adı</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Kategori</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Stok Miktarı</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Fiyat</th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Durum</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.id} className="text-gray-700">
                <td className="text-left py-3 px-4">{item.name}</td>
                <td className="text-left py-3 px-4">{item.category}</td>
                <td className="text-left py-3 px-4">{item.stock}</td>
                <td className="text-left py-3 px-4">{item.price}</td>
                <td className="text-left py-3 px-4">
                  <span
                    className={`py-1 px-3 rounded-full text-sm ${
                      item.status === 'Aktif'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {item.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
