import { ExitIcon, GearIcon, HamburgerMenuIcon, PersonIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSube } from "../../Context/SubeContext";

interface HeaderProps {
  toggleSidebar: () => void; // Sidebar'ı açıp kapatma fonksiyonu prop olarak alınıyor
}
// Header componenti içinde
const Header: React.FC<HeaderProps> = ({ toggleSidebar }) => {
  const [kullaniciAdi, setKullaniciAdi] = useState("");
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const { selectedSubeID, changeSube, subeler } = useSube();
  const navigate = useNavigate(); // navigate hook'unu tanımla

  useEffect(() => {
    const kullaniciBilgileri = JSON.parse(
      localStorage.getItem("kullaniciBilgileri") || "{}"
    );
    setKullaniciAdi(kullaniciBilgileri.adSoyad || "admin");
  }, []);

  const handleSubeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedID = Number(event.target.value);
    changeSube(selectedID);
  };

  const handleLogout = () => {
    // selectedSubeID hariç tüm localStorage verilerini sil
    const subeID = localStorage.getItem("selectedSubeID");
    localStorage.clear(); // Tüm localStorage'ı temizle
    if (subeID) {
      localStorage.setItem("selectedSubeID", subeID); // selectedSubeID'yi geri yükle
    }
    navigate("/"); // Login sayfasına yönlendir
  };

  return (
    <header className="flex justify-between bg-white items-center mb-8 border-b pb-4 border-gray-200 p-4">
      {/* Sidebar toggle button */}
      <button onClick={toggleSidebar} className="p-2 rounded-md bg-gray-200">
        <HamburgerMenuIcon />
      </button>

      <div className="flex items-center space-x-4">
        {subeler && (
          <select
            className="bg-white p-2 border border-gray-300 rounded"
            value={selectedSubeID || ""}
            onChange={handleSubeChange}
          >
            {subeler.map((sube) => (
              <option key={sube.subeID} value={sube.subeID}>
                {sube.subeAdi}
              </option>
            ))}
          </select>
        )}
        <input
          type="text"
          placeholder="Sipariş veya müşteri ara..."
          className="p-2 border border-gray-300 rounded w-80"
        />
      </div>

      <div className="relative">
        <button
          className="p-2 bg-white rounded border border-gray-300 flex items-center"
          onClick={() => setProfileMenuOpen(!profileMenuOpen)}
        >
          <PersonIcon className="mr-2" />
          {kullaniciAdi}
          <svg
            className={`ml-2 transition-transform transform ${
              profileMenuOpen ? "rotate-180" : "rotate-0"
            }`}
            width="12"
            height="12"
            viewBox="0 0 24 24"
          >
            <path fill="currentColor" d="M12 15l-6-6h12z"></path>
          </svg>
        </button>

        {profileMenuOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-md">
            <div className="p-4">
              <p className="font-bold">{kullaniciAdi}</p>
              <p className="text-gray-500">admin@example.com</p>
            </div>
            <ul>
              <li className="p-4 hover:bg-gray-100 flex items-center">
                <PersonIcon className="mr-2" />
                Profil
              </li>
              <li className="p-4 hover:bg-gray-100 flex items-center">
                <GearIcon className="mr-2" />
                Ayarlar
              </li>
              <li
                className="p-4 hover:bg-gray-100 flex items-center cursor-pointer"
                onClick={handleLogout} // Çıkış yap fonksiyonunu tetikle
              >
                <ExitIcon className="mr-2" />
                Çıkış Yap
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};
export default Header;