import React from "react";
import { Dialog } from "@headlessui/react";
import { FaTimes } from "react-icons/fa";
import { X, Upload } from "lucide-react";

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  children: React.ReactNode;
  onSave: () => void;
  width?: string; // Boyutlandırma için opsiyonel prop
  height?: string; // Boyutlandırma için opsiyonel prop
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  title,
  children,
  onSave,
  width = "max-w-md",
  height = "h-auto",
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      className="fixed inset-0 z-50 flex items-center justify-center"
    >
      {/* Arka Plan */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal İçeriği */}
      <div
        className={`relative bg-white rounded-lg shadow-lg p-6 ${width} ${height} w-full z-50`}
      >
        {/* Kapatma Butonu */}
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 transition-colors"
          onClick={closeModal}
          aria-label="Close modal"
        >
          <X className="h-6 w-6" />
        </button>

        {/* Başlık */}
        <Dialog.Title className="text-lg font-semibold text-gray-900 mb-4 border-b pb-3">
          {title}
        </Dialog.Title>

        {/* İçerik */}
        <Dialog.Description className="mb-4">{children}</Dialog.Description>
      </div>
    </Dialog>
  );
};

export default Modal;
